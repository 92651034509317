import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

import Home from "../components/Home/Home"


const IndexPage = () => (
  <Layout>
    <SEO 
    title="Home"
    keywords="translation, localisation, localization, English to Tagalog, Tagalog to English, cultural translation, cultural naturalization, cultural naturalisation, production management, voice acting, voice actors, voice actor management, dubbing, voice over, Australia, Philippines, Sleigh Platform, subtitles, subtitling, Synchresis, language services"
    />
    <Home/>
  </Layout>
)

export default IndexPage
