import React, { Component } from 'react'
import { Link } from 'gatsby';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay,Pagination, Navigation } from 'swiper';

import "swiper/css";
import "swiper/css/pagination"
import "swiper/css/navigation"

import '../GridItem/GridItem'
import '../../App.css'
import './Home.css'
import GridItem from '../GridItem/GridItem'

import client from "../../client"
import { urlFor } from '../../utils/image-builder'
import moment from 'moment';
import Button from '../Button/Button'

import "animate.css/animate.min.css";

import WhateWeDoImg from '../../assets/what-we-do.jpg'
import StoryImg from '../../assets/who-we-are.jpg'
import ContentImg from '../../assets/JarDwellers-square.png'
import BlankImg from '../../assets/blank-landscape.jpg'
import LogoWhite from "../../assets/logo/SleighLogo-White.png"


// install Swiper modules
SwiperCore.use([Autoplay,Pagination,Navigation]);

/**
 *  Home page component
 *  @version 1.1
 *  @author Nadia Mayangputri
 */ 
export default class Home extends Component {

    constructor(props) {
        super(props)

        this.state = {
            // Header overlay, the opacity will be updated on scroll
            overlay: 'linear-gradient(to right, rgba(0,0,0,0.3), rgba(0,0,0,0))',
            // Stores data on the most recent post
            recentPost: {},
            postImage: "",
            postUrl: ""
        }

        this.handleScroll = this.handleScroll.bind(this)

    }

    componentDidMount() {
        // Listens for scrolling...
        window.addEventListener('scroll', this.handleScroll);

        let opportunitiesID = '9092b6fc-4732-4318-a757-0fb3e4ddca58'

        // Fetch the most recent Journal post by getting the first item of a list of posts ordered by publish date
        // excludes post on Opportnities
        client.fetch(`*[_type == "post" && category._ref != "${opportunitiesID}"] | order(publishedAt desc)`)
            .then(data => {
                this.setState({
                    recentPost: data[0],
                    postImage: urlFor(data[0].mainImage.asset._ref),
                    postUrl: data[0].slug.current
                })
            })
            .catch(err => console.log(err))

    }
    
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
    
    /**
     *  Trigger micro-interactions on scroll
     */
    handleScroll() {
        let scrollTop = document.documentElement.scrollTop
        let overlayOpacity;

        // Opacity of overlay increases as we scroll down, hence the opacity value is set relative to the scroll position
        overlayOpacity = (scrollTop/1000)*1.3

        // Updates opacity of the header overlay
        this.setState({
            overlay: `linear-gradient(to right, rgba(0,0,0,${0.2 + overlayOpacity}), rgba(0,0,0,${overlayOpacity}))`
        });
    }

    render() {
        let recentPost = this.state.recentPost;
        let postImage = this.state.postImage;
        let postUrl = this.state.postUrl;

        // let opportunitiesID = '9092b6fc-4732-4318-a757-0fb3e4ddca58'
        
        return(
            <div>
                <div id="home-header-container">
               
                    <Swiper
                    navigation={true} 
                    className="mySwiper"
                    autoplay={{
                        "delay": 3500,
                        "disableOnInteraction": false,
                    }}
                    slidesPerView={1} spaceBetween={30} loop={true} pagination={{
                        "clickable": true
                    }} 
                    >
                        <SwiperSlide> 
                            <div className='header-slide main'>
                                <div className="home-header-overlay">
                                    <img src={LogoWhite} />
                                    <div className="home-header-text">
                                        <div className="line"></div>
                                        <h1 className="animated slideInDown">Distribution, Localization and Talent for the International Market</h1>
                                        <div className="header-text animated fadeIn">
                                            <p>
                                            Content Distribution. Localization. Cultural Naturalization. Production Management. Talent.
                                            </p>
                                            <br />
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='header-slide original-content'>
                                <div className="home-header-overlay">
                                <img src={LogoWhite} />

                                    <div className="home-header-text">
                                        
                                        <h2 className="animated slideInDown">Original Content</h2>
                                        <div className="header-text animated fadeIn">
                                            <p>
                                            Cross Cultural. Human Centred. Diverse Voices.
                                            </p>
                                            <br />
                                        </div>
                                        <div className='line'></div>
                                        <Link to='/original-content'><div className="btn blue-btn">LEARN MORE</div></Link>
                                    </div> 
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                                <div className='header-slide film-fund'>
                                    <div className="home-header-overlay">
                                    <img src={LogoWhite} />
                                    
                                        <div className="home-header-text">
                                    
                                            <h2 className="animated slideInDown">Film Fund</h2>
                                            <div className="header-text animated fadeIn">
                                                <p>
                                                    Compelling Content and Fresh Ideas told and sold across cultural borders
                                                </p>
                                                <br />
                                            </div>

                                            <div className='line'></div>
                                            <Link to='/film-fund'><div className="btn pink-btn">LEARN MORE</div></Link>
                                        
                                        </div> 
                                    </div>
                                </div>

                        </SwiperSlide>
                    
                    {/* <SwiperSlide>
                    <div className='header-slide content'>
                                <div className="home-header-overlay">
                                    <div className="home-header-text">
                                        <h1 className="animated slideInDown">Our Content</h1>
                                        <h3 className="animated slideInUp"><span>Please <b>contact us</b> to view our content catalogue or talk to us about international distribution opportunities</span></h3>
                                    </div> 
                                </div>
                            </div>
                    </SwiperSlide> */}
                    
                    </Swiper>
                    {/* <div id="home-header-overlay" style={{background: this.state.overlay}}>
                       <div className="home-header-text">
                           <h1 className="animated slideInDown">Distribution, Localization and Talent for the International Market</h1>
                           <h3 className="animated slideInUp">Content Distribution. Localization. Cultural Naturalization. Production Management. Talent.</h3>
                        </div> 
                    </div> */}
                </div>


                
                <div className="main-container" id="home-container">
                    <div className="main-content">
                        <div className="grid-container">
                            <div id="what-we-do-box">
                                <Link to={"/services"}>
                                    <GridItem 
                                        title="What We Do"
                                        content="We source shows that resonate with audiences around the world, and give them the best chance to do well in 
                                        foreign markets through dubbing, translation and cultural naturalization. We invest in our talent and allow you to 
                                        deal with one company – us – for your content and localization needs."
                                        image={WhateWeDoImg}
                                        bgColor="rgba(255, 147, 31,0.92)"
                                    />
                                </Link>
                            </div>
                            
                            <div id="who-we-are-box">
                                <Link to={"/about-us"}>
                                    <GridItem 
                                        title="Our Story"
                                        content="Since 2014 Sleigh Group has brought quality and synchresis to the biggest franchises 
                                        in entertainment, growing from a small local player to a truly international content company."
                                        image={StoryImg}
                                        bgColor="rgba(70, 103, 224, 0.9)"
                                    />
                                </Link>
                            </div>

                            <div id="our-content">
                                
                                    <GridItem 
                                        title="Our Content"
                                        content={<div className="btn-container"> 
                                         <p>Click below to view our current projects, or pitch us your own</p>
                                         <Link to={"/original-content"}><Button buttonClass={"simple-button"} buttonText={"View our content"}></Button></Link>
                                        <a href={"mailto: originalcontent@sleigh.group?subject=Original Content Inquiry"} style={{marginLeft: 10}}>
                                          <Button buttonClass={"simple-button"} buttonText={"Contact us"}></Button>
                                        </a>
                                      </div>}
                                        image={ContentImg}
                                        bgColor="rgba(40, 47, 72, 0.8)"
                                    />
                            </div>
                            
                            <div id="journal-box">
                                <Link to={`/journal/${postUrl}`}>
                                    <GridItem 
                                        title="Journal"
                                        journalHeader={recentPost.title}
                                        journalDate={moment(recentPost.publishedAt).format('LL')}
                                        content={<p>{recentPost.textPreview}</p>}
                                        image={postImage}
                                        url={postUrl}
                                        bgColor="rgba(0,0,0,0.9)"
                                        isJournal = {true}
                                    />
                               </Link> 
                            </div>
                            
                            <div id="our-work-box">
                                <a href={`mailto:contact@sleigh.group`}>
                                    <GridItem 
                                        title="Our Work"
                                        content={
                                            <span>Please <b>contact us</b> if you would like to see samples of our previous work.</span>}
                                        image={BlankImg}
                                        bgColor="linear-gradient(275deg, #ff5554, #cb1670)"
                                    />
                                </a>
                            </div>
                            
                            <div id="opportunities-box">                               
                                <Link to='/opportunities'>
                                    <GridItem 
                                        title="Opportunities"
                                        content="Come work with us."
                                        image={BlankImg}
                                        bgColor="linear-gradient(155deg, rgb(120, 135, 232), rgb(135, 206, 235))"
                                    />
                                </Link>
                            </div>
                            
                        </div>
                        
                    </div>
                </div>
            </div>
        )
    }
}